export const erpSystems: string[] = ["nl", "be", "ch", "uk", "lu", "de", "fr"];

export const gtmIds: Record<string, string> = {
  nl: "N27CV4",
  be: "KVDBGV",
  ch: "NHMM4V",
  uk: "5S5HHW",
  lu: "5FKMQP",
  de: "5SRBSX",
  fr: "5PCPXL",
};

export const getGTMId = (): string => {
  let erpSystem: string = "";

  const [erp] = window.location.hostname.split(".").slice(-1);

  if (erpSystems.indexOf(erp.toLowerCase()) !== -1) {
    erpSystem = erp;
  }

  return "GTM-" + gtmIds[erpSystem || "nl"];
};
