import "./polyfills";
/**
 * DO NOT REORDER THESE IMPORTS UNDER ANY CIRCUMSTANCES
 * CREATE REACT APP REQUIRES THE IE11 POLYFILL TO BE THE FIRST LINE OF SRC/INDEX.TSX
 * DO NOT REORDER THESE IMPORTS UNDER ANY CIRCUMSTANCES
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./styles/GlobalStyles";
import { LanguageProvider } from "@eriksdigital/atomic-ui/components";
import { defaultTheme } from "@eriksdigital/atomic-ui/styles";
import { fetchTranslations } from "./utils/languageUtils";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { getGTMId } from "./utils/getGTMId";
import TagManager from "react-gtm-module";

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

TagManager.initialize({ gtmId: getGTMId() });

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={defaultTheme}>
        <LanguageProvider
          shouldUpdateURL
          hasNewDomain={true}
          fetchTranslations={fetchTranslations}
        >
          <App />
        </LanguageProvider>
      </ThemeProvider>
    </HelmetProvider>
    <GlobalStyles />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
}
