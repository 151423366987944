import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  height: 64px;
  border-bottom: 1px solid #c8ccd6;
`;

export const HeaderInnerWrapper = styled.div`
  width: 100%;
  max-width: 75em;
  margin: 0 auto;
`;

export const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.9375rem;
`;

export const LogoWrapper = styled.a`
  width: 100px;
  height: 64px;
  display: flex;
  > div {
    display: flex;
  }
  svg {
    width: 100%;
  }
`;
