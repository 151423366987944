export type EnvironmentsType = "test" | "qa" | "acc" | "prod";

const environments: { [key in EnvironmentsType]: string } = {
  test: "dev.",
  qa: "test.",
  acc: "test.",
  prod: "",
};

type ShopNames = "nl" | "be" | "lu" | "fr" | "ch" | "uk" | "de";

const shopNames: { [key in ShopNames]: string } = {
  nl: "shop.eriks.nl/",
  be: "shop.eriks.be/",
  lu: "shop.eriks.lu/",
  fr: "shop.eriks.fr/",
  ch: "shop.maagtechnic.ch/",
  uk: "shop.eriks.co.uk/",
  de: "shop.eriks.de/",
};

export const constructUrl = (
  environment: EnvironmentsType = "test",
  erpSystem: ShopNames = "nl",
  language: string = "nl"
) => {
  return `https://${environments[environment]}${shopNames[erpSystem]}${language}`;
};
