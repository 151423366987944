import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Header from "./components/Header/index";
import CookieStatement from "./containers/CookieStatement/CookieStatement";
import { useBasenamePath } from "./utils/useBasenamePath";
import { Main } from "./App.styled";
import TagManager from "react-gtm-module";

function App() {
  const basenamePath = useBasenamePath();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        page: {
          url: window.location.href,
          path: window.location.pathname,
        },
        event: "virtualPageView",
      },
    });
  }, []);

  return (
    <Router>
      <Fragment>
        <Header />
        <Main>
          <Switch>
            <Route exact path={basenamePath} />
            <Route
              exact
              path={basenamePath + "/cookies-page"}
              component={CookieStatement}
            />
            <Redirect from="*" to={basenamePath} />
          </Switch>
        </Main>
      </Fragment>
    </Router>
  );
}

export default App;
